import { render, staticRenderFns } from "./QQVisualizzaQuestionarioSanitario.vue?vue&type=template&id=e4d7bf04&"
import script from "./QQVisualizzaQuestionarioSanitario.vue?vue&type=script&lang=js&"
export * from "./QQVisualizzaQuestionarioSanitario.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QItemLabel,QCheckbox});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e4d7bf04')) {
      api.createRecord('e4d7bf04', component.options)
    } else {
      api.reload('e4d7bf04', component.options)
    }
    module.hot.accept("./QQVisualizzaQuestionarioSanitario.vue?vue&type=template&id=e4d7bf04&", function () {
      api.rerender('e4d7bf04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/QuestionariAssuntivi/QQVisualizzaQuestionarioSanitario.vue"
export default component.exports