import { render, staticRenderFns } from "./VisualizzaQuestionarioAssuntivo.vue?vue&type=template&id=1808cacb&"
import script from "./VisualizzaQuestionarioAssuntivo.vue?vue&type=script&lang=js&"
export * from "./VisualizzaQuestionarioAssuntivo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpinnerHourglass});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1808cacb')) {
      api.createRecord('1808cacb', component.options)
    } else {
      api.reload('1808cacb', component.options)
    }
    module.hot.accept("./VisualizzaQuestionarioAssuntivo.vue?vue&type=template&id=1808cacb&", function () {
      api.rerender('1808cacb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Quotazioni/VisualizzaQuestionarioAssuntivo.vue"
export default component.exports