var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.domande_caricate
    ? _c(
        "div",
        {
          staticClass: "row justify-center",
          on: {
            input: function ($event) {
              return _vm.resultDomande()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "col-12 col-md-10", attrs: { align: "left" } },
            [
              _vm._m(0),
              _c(
                "q-list",
                { attrs: { separator: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Cognome, Nome / Ragione Sociale"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiCliente("cognome")) +
                                " " +
                                _vm._s(_vm.getDatiCliente("nome")) +
                                " " +
                                _vm._s(_vm.getDatiCliente("ragione_sociale"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Indirizzo"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiResidenzaCliente("indirizzo"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Civico"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiResidenzaCliente("civico"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Comune"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiResidenzaCliente("comune"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Provincia"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiResidenzaCliente("provincia"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("CAP"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(_vm._s(_vm.getDatiResidenzaCliente("cap"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Codice Fiscale"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiCliente("codice_fiscale"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Partita IVA"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(_vm._s(_vm.getDatiCliente("partita_iva"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Comune di nascita"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiCliente("comune_nascita"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Provincia di nascita"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiCliente("provincia_nascita"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _vm.isMultirischioCasa
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio della casa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Tipologia di utilizzo dell'abitazione"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "tipo_dimora"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Tipo di Abitazione")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipo_abitazione"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "A quale piano si trova l'Immobile?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "piano_abitazione"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Indirizzo dell'Immobile")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(_vm._s(_vm.IndirizzoCasa) + " "),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Comune dell'Immobile")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(_vm._s(_vm.ComuneCasa) + " "),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Cap dell'Immobile")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(_vm._s(_vm.CapCasa) + " "),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "provincia_fabbricato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "provincia_fabbricato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Quanti sono i metri quadri dell`Immobile?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "metri_quadri"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "anno_costruzione_fabbricato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "anno_costruzione_fabbricato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "anno_ultima_ristrutturazione"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "anno_ultima_ristrutturazione"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(1),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "multirischio_casa"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "multirischio_casa"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.havePolizzaStessoRischio === "SI",
                                        expression:
                                          " havePolizzaStessoRischio === 'SI' ",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Impresa di assicurazione"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "compagnia"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Data di scadenza della copertura"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "scadenza_copertura"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_schifata"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                this.PolizzaSchifataDaCompagnia === "SI"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Breve descrizione del motivo del rifiuto"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.domande_multirischio_casa
                                                .motivo_rifiuto_compagnia
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Si sono verificati sinistri negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_casa"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_casa"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_casa"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_casa"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_casa"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_casa"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_casa"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_casa"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isInfortuni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Infortuni")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaInfortuni(
                                              "contraente_uguale_assicurato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaInfortuniValue(
                                            "contraente_uguale_assicurato"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getDomandaInfortuniValue(
                                  "contraente_uguale_assicurato"
                                ) === "NO"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaInfortuni(
                                                  "tipo_persona"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaInfortuniValue(
                                                "tipo_persona"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getDomandaInfortuniValue(
                                  "contraente_uguale_assicurato"
                                ) === "NO" &&
                                _vm.getDomandaInfortuniValue("tipo_persona") ===
                                  "PERSONA_FISICA"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "cognome"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "cognome"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "nome"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "nome"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "sesso"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "sesso"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm.getDomandaInfortuniValue(
                              "contraente_uguale_assicurato"
                            ) === "NO" &&
                            _vm.getDomandaInfortuniValue("tipo_persona") ===
                              "PERSONA_FISICA"
                              ? [
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "data_nascita"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "data_nascita"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "stato_nascita_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "stato_nascita_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "comune_nascita_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "comune_nascita_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "cap_nascita_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "cap_nascita_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "provincia_nascita_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "provincia_nascita_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "codice_fiscale"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "codice_fiscale"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "indirizzo_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "indirizzo_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "comune_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "comune_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "cap_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "cap_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "provincia_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "provincia_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.getDomandaInfortuniValue(
                              "contraente_uguale_assicurato"
                            ) === "NO" &&
                            _vm.getDomandaInfortuniValue("tipo_persona") ===
                              "PROFESSIONISTA"
                              ? [
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "cognome"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "cognome"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "nome"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "nome"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "partita_iva"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "partita_iva"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.getDomandaInfortuniValue(
                              "contraente_uguale_assicurato"
                            ) === "NO" &&
                            _vm.getDomandaInfortuniValue("tipo_persona") ===
                              "SOCIETA"
                              ? [
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "ragione_sociale"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "ragione_sociale"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "partita_iva"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "partita_iva"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "indirizzo_sede_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "indirizzo_sede_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "comune_sede_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "comune_sede_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "cap_sede_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "cap_sede_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaInfortuni(
                                                    "provincia_sede_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaInfortuniValue(
                                                  "provincia_sede_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qual'è l'ttività svolta dall'Assicurato?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "attivita_infortuni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "L`Assicurato ha più di 75 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "eta_assicurato_infortuni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "accertamenti_diagnostici"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "accertamenti_diagnostici"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "subito_ricoveri"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "subito_ricoveri"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "dipendenze"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "dipendenze"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "trauni_fratture"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "trauni_fratture"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "pensione_rendita_invalidita_infortunio"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "pensione_rendita_invalidita_infortunio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "pensione_rendita_invalidita_malattia"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "pensione_rendita_invalidita_malattia"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "tipologia_copertura"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipologia_copertura"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(2),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaInfortuni(
                                                  "motivo_rifiuto_compagnia"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaInfortuniValue(
                                                "motivo_rifiuto_compagnia"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_infortuni"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_infortuni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_infortuni"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_infortuni"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_infortuni"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(3),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "infortuni"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive("infortuni"),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isMultirischioCommercio
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio del Commercio")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "settore_merceologico_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "settore_merceologico_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "merce_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "merce_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "attivita_aggiuntiva_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "attivita_aggiuntiva_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "attivita_aggiuntiva_commercio"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "settore_merceologico_aggiuntivo_commercio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "settore_merceologico_aggiuntivo_commercio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "attivita_aggiuntiva_commercio"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "merce_commercio_aggiuntiva"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "merce_commercio_aggiuntiva"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "numero_soci_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "numero_soci_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "dipendenti_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "dipendenti_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "dipendenti_commercio"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "numero_dipendenti_commercio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "numero_dipendenti_commercio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "materiale_costruzione_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "materiale_costruzione_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaCommercio(
                                              "numero_piani_edificio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaCommercioValue(
                                            "numero_piani_edificio"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaCommercio(
                                              "metri_quadri"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaCommercioValue(
                                            "metri_quadri"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaCommercio(
                                              "anno_costruzione"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaCommercioValue(
                                            "anno_costruzione"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaCommercio(
                                              "ubicazione_diverso_sede"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaCommercioValue(
                                            "ubicazione_diverso_sede"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.getDomandaCommercioValue(
                              "ubicazione_diverso_sede"
                            ) === "SI"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaCommercio(
                                                  "indirizzo_commercio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaCommercioValue(
                                                "indirizzo_commercio"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaCommercio(
                                                  "comune_commercio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaCommercioValue(
                                                "comune_commercio"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaCommercio(
                                                  "cap_commercio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaCommercioValue(
                                                "cap_commercio"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaCommercio(
                                                  "provincia_commercio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaCommercioValue(
                                                "provincia_commercio"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(4),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "motivo_rifiuto_compagnia"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "motivo_rifiuto_compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_commercio"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_commercio"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_commercio"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_commercio"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_commercio"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_commercio"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_commercio"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_commercio"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(5),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "multirischio_commercio"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "multirischio_commercio"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isMultirischioArtigiano
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio dell`Artigianato")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "settore_merceologico"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "settore_merceologico"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "merce_artigiano"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "merce_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "numero_soci_artigiano"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "numero_soci_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "dipendenti_artigiano"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "dipendenti_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "dipendenti_artigiano"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "numero_dipendenti_artigiano"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "numero_dipendenti_artigiano"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "materiale_costruzione_artigiano"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "materiale_costruzione_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaArtigiano(
                                              "numero_piani_edificio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaArtigianoValue(
                                            "numero_piani_edificio"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaArtigiano(
                                              "metri_quadri"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaArtigianoValue(
                                            "metri_quadri"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaArtigiano(
                                              "anno_costruzione"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaArtigianoValue(
                                            "anno_costruzione"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaArtigiano(
                                              "ubicazione_diverso_sede"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaArtigianoValue(
                                            "ubicazione_diverso_sede"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.getDomandaArtigianoValue(
                              "ubicazione_diverso_sede"
                            ) === "SI"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaArtigiano(
                                                  "indirizzo_artigiano"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaArtigianoValue(
                                                "indirizzo_artigiano"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaArtigiano(
                                                  "comune_artigiano"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaArtigianoValue(
                                                "comune_artigiano"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaArtigiano(
                                                  "cap_artigiano"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaArtigianoValue(
                                                "cap_artigiano"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaArtigiano(
                                                  "provincia_artigiano"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaArtigianoValue(
                                                "provincia_artigiano"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(6),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaArtigiano(
                                                  "motivo_rifiuto_compagnia"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaArtigianoValue(
                                                "motivo_rifiuto_compagnia"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_artigiano"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_artigiano"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_artigiano"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_artigiano"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_artigiano"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_artigiano"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_artigiano"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_artigiano"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(7),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "multirischio_artigiano"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "multirischio_artigiano"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isGlobaleFabbricato
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio del Condominio")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "anno_costruzione_fabbricato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "anno_costruzione_fabbricato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFabbricato(
                                              "anno_ristrutturazione"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFabbricatoValue(
                                            "anno_ristrutturazione"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFabbricato(
                                              "totale_fabbricati"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFabbricatoValue(
                                            "totale_fabbricati"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFabbricato(
                                              "piani_interrati"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFabbricatoValue(
                                            "piani_interrati"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFabbricato(
                                              "piani_fuori_terra"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFabbricatoValue(
                                            "piani_fuori_terra"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "negozi_cinema_fabbricato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "negozi_cinema_fabbricato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFabbricato(
                                              "negozi_cinema_fabbricato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFabbricatoValue(
                                            "negozi_cinema_fabbricato"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm
                                  .getRispostaAnalisiRischioFromName(
                                    "negozi_cinema_fabbricato"
                                  )
                                  .includes("cinema")
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaFabbricato(
                                                  "metri_quadri_cinema"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaFabbricatoValue(
                                                "metri_quadri_cinema"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm
                                  .getRispostaAnalisiRischioFromName(
                                    "negozi_cinema_fabbricato"
                                  )
                                  .includes("negozi")
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaFabbricato(
                                                  "metri_quadri_negozi"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaFabbricatoValue(
                                                "metri_quadri_negozi"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm
                                  .getRispostaAnalisiRischioFromName(
                                    "negozi_cinema_fabbricato"
                                  )
                                  .includes("negozi")
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaFabbricato(
                                                  "attivita_negozi"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm
                                                .getDomandaFabbricatoValue(
                                                  "attivita_negozi"
                                                )
                                                .replace("\n", "<br>")
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFabbricato(
                                              "indirizzo_condominio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFabbricatoValue(
                                            "indirizzo_condominio"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFabbricato(
                                              "comune_condominio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFabbricatoValue(
                                            "comune_condominio"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFabbricato(
                                              "cap_condominio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFabbricatoValue(
                                            "cap_condominio"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFabbricato(
                                              "provincia_condominio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFabbricatoValue(
                                            "provincia_condominio"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaFabbricato(
                                                  "motivo_rifiuto_compagnia"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaFabbricatoValue(
                                                "motivo_rifiuto_compagnia"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_fabbricato"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_fabbricato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_fabbricato"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_fabbricato"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_fabbricato"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_fabbricato"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_fabbricato"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_fabbricato"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_fabbricato"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(8),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "globale_fabbricato"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "globale_fabbricato"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isProdottoVirus
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Polizza 3-Virus")]),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_globale_fabbricato.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_globale_fabbricato
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_virus"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_virus"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(9),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata("virus")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive("virus"),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isProdottoTutelaReddito
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Protezione del reddito")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "eta_assicurato_reddito"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "eta_assicurato_reddito"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipo_lavoro_reddito"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipo_lavoro_reddito"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiNecessitafromName(
                                  "tipologia_attivita_persona"
                                ) === "DIPENDENTE_PRIVATO"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tempo_indeterminato_reddito"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tempo_indeterminato_reddito"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiNecessitafromName(
                                  "tipologia_attivita_persona"
                                ) === "DIPENDENTE_PUBBLICO"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tempo_indeterminato_pubblico_reddito"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tempo_indeterminato_pubblico_reddito"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiNecessitafromName(
                                  "tipologia_attivita_persona"
                                ) === "PROFESSIONISTA"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiNecessitafromName(
                                  "tipologia_attivita_persona"
                                ) === "IMPRENDITORE"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiNecessitafromName(
                                  "tipologia_attivita_persona"
                                ) === "AZIENDA"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaTutelaReddito(
                                              "descrizione_lavoro_svolto"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaTutelaRedditoValue(
                                            "descrizione_lavoro_svolto"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "perdita_impiego_reddito"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "perdita_impiego_reddito"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTutelaReddito(
                                                  "motivo_rifiuto_compagnia"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTutelaRedditoValue(
                                                "motivo_rifiuto_compagnia"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(10),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "tutela_reddito"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive("tutela_reddito"),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isVeterinaria
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Spese Veterinarie")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "totale_animali_veterinaria"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "totale_animali_veterinaria"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "animali_rabbiosio_veterinaria"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "animali_rabbiosio_veterinaria"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c("q-item-section", [
                                  _vm._v("Tipo di Animale"),
                                ]),
                                _c("q-item-section", [_vm._v("Nome")]),
                                _c("q-item-section", [_vm._v("Razza")]),
                                _c("q-item-section", [_vm._v("Eta` anni")]),
                                _c("q-item-section", [_vm._v("Num.Microchip")]),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaVeterinaria(
                                              "tipo_animale1"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaVeterinariaValue(
                                            "tipo_animale1"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaVeterinaria(
                                              "nome_animale1"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaVeterinariaValue(
                                            "nome_animale1"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaVeterinaria(
                                              "razza_animale1"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaVeterinariaValue(
                                            "razza_animale1"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaVeterinaria(
                                              "eta_animale1"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaVeterinariaValue(
                                            "eta_animale1"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaVeterinaria(
                                              "microchip_animale1"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaVeterinariaValue(
                                            "microchip_animale1"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.totale_animali > 1
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "tipo_animale2"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "tipo_animale2"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "nome_animale2"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "nome_animale2"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "razza_animale2"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "razza_animale2"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "eta_animale2"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "eta_animale2"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "microchip_animale2"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "microchip_animale2"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_animali > 2
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "tipo_animale3"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "tipo_animale3"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "nome_animale3"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "nome_animale3"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "razza_animale3"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "razza_animale3"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "eta_animale3"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "eta_animale3"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "microchip_animale3"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "microchip_animale3"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_animali > 3
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "tipo_animale4"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "tipo_animale4"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "nome_animale4"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "nome_animale4"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "razza_animale4"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "razza_animale4"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "eta_animale4"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "eta_animale4"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "microchip_animale4"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "microchip_animale4"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_animali > 4
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "tipo_animale5"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "tipo_animale5"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "nome_animale5"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "nome_animale5"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "razza_animale5"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "razza_animale5"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "eta_animale5"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "eta_animale5"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaVeterinaria(
                                                  "microchip_animale5"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaVeterinariaValue(
                                                "microchip_animale5"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_veterinaria"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_veterinaria"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_veterinaria"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_veterinario"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_veterinario"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_veterinario"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_veterinario"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_veterinario"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_veterinario"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(11),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "spese_veterinarie"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "spese_veterinarie"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isMultirischioAlbergo
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio Albergo")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAlbergo(
                                              "ubicazione_uguale_sede"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAlbergoValue(
                                            "ubicazione_uguale_sede"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getDomandaAlbergoValue(
                                  "ubicazione_uguale_sede"
                                ) === "no"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaAlbergo(
                                                    "indirizzo"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaAlbergoValue(
                                                  "indirizzo"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaAlbergo(
                                                    "comune_albergo"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaAlbergoValue(
                                                  "comune_albergo"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaAlbergo(
                                                    "cap_albergo"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaAlbergoValue(
                                                  "cap_albergo"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaAlbergo(
                                                    "provincia_albergo"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaAlbergoValue(
                                                  "provincia_albergo"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAlbergo(
                                              "denominazione_struttura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAlbergoValue(
                                            "denominazione_struttura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAlbergo(
                                              "posti_letto"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAlbergoValue(
                                            "posti_letto"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAlbergo(
                                              "valutazione"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAlbergoValue(
                                            "valutazione"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "struttura_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "struttura_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "materiale_costruzione_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "materiale_costruzione_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "anno_costruzione_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "anno_costruzione_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "piani_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "piani_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAlbergo(
                                              "superficie_metri_quadri"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAlbergoValue(
                                            "superficie_metri_quadri"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAlbergo(
                                              "fatturato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAlbergoValue(
                                            "fatturato"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "numero_soci_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "numero_soci_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "dipendenti_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "dipendenti_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "numero_dipendenti_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "numero_dipendenti_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAlbergo(
                                              "motivo_rifiuto_compagnia"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAlbergoValue(
                                            "motivo_rifiuto_compagnia"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_albergo"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_albergo"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_albergo"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_albergo"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_albergo"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_albergo"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_albergo"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_albergo"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(12),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "multirischio_albergo"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "multirischio_albergo"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isDeO
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Polizza D & O")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "forma_giuridica_deo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "forma_giuridica_deo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "forma_giuridica_deo"
                                ) === "ASSOCIAZIONE"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_associazione_deo"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_associazione_deo"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "forma_giuridica_deo"
                                ) === "SOCIETA"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_societa_deo"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_societa_deo"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "ateco_deo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "ateco_deo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "attivo_bilancio_deo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "attivo_bilancio_deo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaDEO(
                                              "motivo_rifiuto_compagnia"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaDEOValue(
                                            "motivo_rifiuto_compagnia"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_deo"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_deo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(13),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata("deo")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive("deo"),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isPolizzaViaggi
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Polizza Viaggi")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipologia_viaggio"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipologia_viaggio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "attivita_viaggi"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "attivita_viaggi"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "tipologia_viaggio"
                                ) !== "SHENGEN"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "area_partenza_viaggio"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "area_partenza_viaggio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "tipologia_viaggio"
                                ) === "RILASCIO_RESIDENZA"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "area_partenza_residenza_viaggio"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "area_partenza_residenza_viaggio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "tipologia_viaggio"
                                ) !== "SHENGEN"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "paese_destinazione_viaggio"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "paese_destinazione_viaggio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "tipologia_viaggio"
                                ) === "SHENGEN"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "paese_destinazione_shengen_viaggio"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "paese_destinazione_shengen_viaggio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "data_partenza_viaggio"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "data_partenza_viaggio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "data_rientro_viaggio"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "data_rientro_viaggio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "numero_viaggiatori"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "numero_viaggiatori"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "fieldset",
                          [
                            _c("legend", [_vm._v("Viaggiatore #1")]),
                            _c(
                              "q-list",
                              { attrs: { separator: "" } },
                              [
                                _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaViaggi(
                                                  "cognome1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaViaggiValue(
                                                "cognome1"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaViaggi(
                                                  "nome1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaViaggiValue("nome1")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaViaggi("cf1")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaViaggiValue("cf1")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaViaggi(
                                                  "data_nascita1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaViaggiValue(
                                                "data_nascita1"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaViaggi(
                                                  "comune_nascita1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaViaggiValue(
                                                "comune_nascita1"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaViaggi(
                                                  "provincia_nascita1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaViaggiValue(
                                                "provincia_nascita1"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaViaggi(
                                                  "indirizzo1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaViaggiValue(
                                                "indirizzo1"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaViaggi(
                                                  "comune1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaViaggiValue(
                                                "comune1"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaViaggi(
                                                  "cap1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaViaggiValue("cap1")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaViaggi(
                                                  "provincia1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaViaggiValue(
                                                "provincia1"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 1
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #2")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cognome2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cognome2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "nome2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "nome2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cf2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cf2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "data_nascita2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "data_nascita2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune_nascita2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune_nascita2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia_nascita2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia_nascita2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "indirizzo2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "indirizzo2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cap2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cap2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 2
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #3")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cognome3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cognome3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "nome3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "nome3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cf3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cf3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "data_nascita3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "data_nascita3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune_nascita3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune_nascita3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia_nascita3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia_nascita3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "indirizzo3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "indirizzo3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cap3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cap3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 3
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #4")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cognome4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cognome4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "nome4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "nome4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cf4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cf4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "data_nascita4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "data_nascita4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune_nascita4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune_nascita4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia_nascita4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia_nascita4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "indirizzo4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "indirizzo4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cap4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cap4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 4
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #5")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cognome5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cognome5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "nome5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "nome5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cf5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cf5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "data_nascita5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "data_nascita5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune_nascita5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune_nascita5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia_nascita5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia_nascita5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "indirizzo5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "indirizzo5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cap5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cap5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 5
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #6")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cognome6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cognome6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "nome6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "nome6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cf6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cf6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "data_nascita6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "data_nascita6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune_nascita6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune_nascita6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia_nascita6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia_nascita6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "indirizzo6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "indirizzo6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cap6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cap6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 6
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #7")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cognome7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cognome7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "nome7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "nome7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cf7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cf7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "data_nascita7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "data_nascita7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune_nascita7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune_nascita7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia_nascita7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia_nascita7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "indirizzo7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "indirizzo7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cap7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cap7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 7
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #8")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cognome8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cognome8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "nome8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "nome8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cf8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cf8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "data_nascita8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "data_nascita8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune_nascita8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune_nascita8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia_nascita8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia_nascita8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "indirizzo8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "indirizzo8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cap8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cap8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 8
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #9")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cognome9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cognome9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "nome9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "nome9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cf9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cf9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "data_nascita9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "data_nascita9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune_nascita9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune_nascita9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia_nascita9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia_nascita9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "indirizzo9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "indirizzo9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cap9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cap9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 9
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #10")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cognome10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cognome10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "nome10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "nome10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cf10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cf10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "data_nascita10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "data_nascita10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune_nascita10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune_nascita10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia_nascita10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia_nascita10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "indirizzo10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "indirizzo10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "comune10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "comune10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "cap10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "cap10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaViaggi(
                                                      "provincia10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaViaggiValue(
                                                    "provincia10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm._m(14),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "viaggi"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive("viaggi"),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isPolizzaLeasing
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Polizza Leasing")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "durata_leasing"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "durata_leasing"
                                            )
                                          )
                                        ) + " Anni"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "valore_beni_leasing"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "valore_beni_leasing"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaLeasing(
                                              "descrizione_beni_leasing"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaLeasingValue(
                                            "descrizione_beni_leasing"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaLeasing(
                                              "descrizione_attivita_leasing"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaLeasingValue(
                                            "descrizione_attivita_leasing"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaLeasing(
                                              "ubicazione_uguale_sede"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaLeasingValue(
                                            "ubicazione_uguale_sede"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.domande_leasing.ubicazione_uguale_sede
                                  .value == "no"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaLeasing(
                                                  "indirizzo"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaLeasingValue(
                                                "indirizzo"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.domande_leasing.ubicazione_uguale_sede.value ==
                            "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaLeasing(
                                                  "comune_leasing"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaLeasingValue(
                                                "comune_leasing"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaLeasing(
                                                  "cap_leasing"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaLeasingValue(
                                                "cap_leasing"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaLeasing(
                                                  "provincia_leasing"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaLeasingValue(
                                                "provincia_leasing"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaLeasing(
                                                  "motivo_rifiuto_compagnia"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaLeasingValue(
                                                "motivo_rifiuto_compagnia"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_leasing"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_leasing"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_leasing"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_leasing"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_leasing"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_leasing"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_leasing"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_leasing"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_leasing"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(15),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "leasing"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive("leasing"),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isPatrimoniale
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("R.C. Patrimoniale")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "numero_cariche_patrimoniale"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "numero_cariche_patrimoniale"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaPatrimoniale(
                                              "contraente_uguale_assicurato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaPatrimonialeValue(
                                            "contraente_uguale_assicurato"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.domande_patrimoniale
                              .contraente_uguale_assicurato.value === "no"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "cognome"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "cognome"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "nome"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "nome"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "codice_fiscale"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "codice_fiscale"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "sesso"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "sesso"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "stato_nascita_patrimoniale"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "stato_nascita_patrimoniale"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "data_nascita"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "data_nascita"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.domande_patrimoniale
                                      .stato_nascita_patrimoniale.value ===
                                    "ITALIA"
                                      ? _c(
                                          "q-item",
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c(
                                                  "q-item-label",
                                                  { attrs: { caption: "" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLabelDomandaPatrimoniale(
                                                          "comune_nascita_patrimoniale"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("q-item-label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getDomandaPatrimonialeValue(
                                                        "comune_nascita_patrimoniale"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c(
                                                  "q-item-label",
                                                  { attrs: { caption: "" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLabelDomandaPatrimoniale(
                                                          "cap_nascita_patrimoniale"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("q-item-label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getDomandaPatrimonialeValue(
                                                        "cap_nascita_patrimoniale"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c(
                                                  "q-item-label",
                                                  { attrs: { caption: "" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLabelDomandaPatrimoniale(
                                                          "provincia_nascita_patrimoniale"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("q-item-label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getDomandaPatrimonialeValue(
                                                        "provincia_nascita_patrimoniale"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "indirizzo_patrimoniale"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "indirizzo_patrimoniale"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "comune_patrimoniale"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "comune_patrimoniale"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "cap_patrimoniale"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "cap_patrimoniale"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "provincia_patrimoniale"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "provincia_patrimoniale"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _c(
                          "fieldset",
                          [
                            _c("legend", [_vm._v("Carica #1")]),
                            _c(
                              "q-list",
                              { attrs: { separator: "" } },
                              [
                                _c(
                                  "q-item",
                                  [
                                    _vm.getRispostaAnalisiRischioFromName(
                                      "numero_cariche_patrimoniale"
                                    ) == 1
                                      ? _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelAnalisiRischioFromName(
                                                      "carica_svolta_patrimoniale"
                                                    )
                                                  ) + " "
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.analizzaRisposta(
                                                    _vm.getDomandaAnalisiRischioFromName(
                                                      "carica_svolta_patrimoniale"
                                                    )
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.getRispostaAnalisiRischioFromName(
                                      "numero_cariche_patrimoniale"
                                    ) > 1
                                      ? _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "carica_svolta1"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "carica_svolta1"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaPatrimoniale(
                                                  "ente_appartenenza1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaPatrimonialeValue(
                                                "ente_appartenenza1"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaPatrimoniale(
                                                  "citta_ente_appartenenza1"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaPatrimonialeValue(
                                                "citta_ente_appartenenza1"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 1
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #2")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "carica_svolta2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "carica_svolta2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "ente_appartenenza2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "ente_appartenenza2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "citta_ente_appartenenza2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "citta_ente_appartenenza2"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 2
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #3")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "carica_svolta3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "carica_svolta3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "ente_appartenenza3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "ente_appartenenza3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "citta_ente_appartenenza3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "citta_ente_appartenenza3"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 3
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #4")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "carica_svolta4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "carica_svolta4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "ente_appartenenza4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "ente_appartenenza4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "citta_ente_appartenenza4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "citta_ente_appartenenza4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 4
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #5")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "carica_svolta5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "carica_svolta5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "ente_appartenenza5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "ente_appartenenza5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "citta_ente_appartenenza5"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "citta_ente_appartenenza5"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 5
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #6")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "carica_svolta6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "carica_svolta6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "ente_appartenenza6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "ente_appartenenza6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "citta_ente_appartenenza6"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "citta_ente_appartenenza6"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 6
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #7")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "carica_svolta7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "carica_svolta7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "ente_appartenenza7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "ente_appartenenza7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "citta_ente_appartenenza7"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "citta_ente_appartenenza7"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 7
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #8")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "carica_svolta8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "carica_svolta8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "ente_appartenenza8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "ente_appartenenza8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "citta_ente_appartenenza8"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "citta_ente_appartenenza8"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 8
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #9")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "carica_svolta9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "carica_svolta9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "ente_appartenenza9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "ente_appartenenza9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "citta_ente_appartenenza9"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "citta_ente_appartenenza9"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 9
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #10")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "carica_svolta10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "carica_svolta10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "ente_appartenenza10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "ente_appartenenza10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaPatrimoniale(
                                                      "citta_ente_appartenenza10"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaPatrimonialeValue(
                                                    "citta_ente_appartenenza10"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaPatrimoniale(
                                                  "motivo_rifiuto_compagnia"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaPatrimonialeValue(
                                                "motivo_rifiuto_compagnia"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_patrimoniale"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_patrimoniale"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_patrimoniale"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_patrimoniale"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_patrimoniale"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_patrimoniale"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_patrimoniale"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_patrimoniale"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_patrimoniale"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(16),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "patrimoniale"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive("patrimoniale"),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isMultirischioUfficio
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio Ufficio")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "attivita_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "attivita_ufficio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "superfice_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "superfice_ufficio"
                                            )
                                          )
                                        ) + " m.q."
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "anno_costruzione_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "anno_costruzione_ufficio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "piani_fabbricato_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "piani_fabbricato_ufficio"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "materiale_costruzione_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "materiale_costruzione_ufficio"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "numero_soci_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "numero_soci_ufficio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "dipendenti_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "dipendenti_ufficio"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "dipendenti_ufficio"
                                ) == "SI"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "numero_dipendenti_ufficio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "numero_dipendenti_ufficio"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaUfficio(
                                              "ubicazione_diverso_sede"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaUfficioValue(
                                            "ubicazione_diverso_sede"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _vm.domande_multirischio_ufficio
                                  .ubicazione_diverso_sede.value == "si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaUfficio(
                                                  "indirizzo_ufficio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaUfficioValue(
                                                "indirizzo_ufficio"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.domande_multirischio_ufficio
                                  .ubicazione_diverso_sede.value == "si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaUfficio(
                                                  "comune_ufficio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaUfficioValue(
                                                "comune_ufficio"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.domande_multirischio_ufficio
                                  .ubicazione_diverso_sede.value == "si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaUfficio(
                                                  "cap_ufficio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaUfficioValue(
                                                "cap_ufficio"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "provincia_fabbricato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "provincia_fabbricato"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaUfficio(
                                                  "motivo_rifiuto_compagnia"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaUfficioValue(
                                                "motivo_rifiuto_compagnia"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_ufficio"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_ufficio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_ufficio"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_ufficio"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_ufficio"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_ufficio"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_ufficio"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_ufficio"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_ufficio"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(17),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "multirischio_ufficio"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "multirischio_ufficio"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isRCEdilizia
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("RC Impresa Edile")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "attivita_rc_edile"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "attivita_rc_edile"
                                            )
                                          )
                                        ) + " Anni"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "numero_soci_rc_edile"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "numero_soci_rc_edile"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "dipendenti_rc_edile"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "dipendenti_rc_edile"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "dipendenti_rc_edile"
                                ) == "SI"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "numero_dipendenti_rc_edile"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "numero_dipendenti_rc_edile"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "perc_lavori_subappalto_rc_edile"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "perc_lavori_subappalto_rc_edile"
                                            )
                                          )
                                        ) + " %"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaRDEdilizia(
                                                      "motivo_rifiuto_compagnia"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaRCEdiliziaValue(
                                                    "motivo_rifiuto_compagnia"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_rc_edile"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_rc_edile"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "sinistri_rc_edile"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "data_sinistro_edilizia"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "data_sinistro_edilizia"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "descrizione_evento_edilizia"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "descrizione_evento_edilizia"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaArtigiano(
                                                    "importo_liquidato_edilizia"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaArtigianoValue(
                                                  "importo_liquidato_edilizia"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(18),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "rc_impresa_edile"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "rc_impresa_edile"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isTCM
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Temporanea caso morte (TCM)")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "eta_tcm"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "eta_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "fumatore_tcm"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "fumatore_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "durata_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "durata_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "capitale_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "capitale_tcm"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaTcm(
                                              "contraente_uguale_assicurato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaTcmValue(
                                            "contraente_uguale_assicurato"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaTcm(
                                              "altezza_tcm"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaTcmValue("altezza_tcm")
                                        ) + " cm"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaTcm("peso_tcm")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaTcmValue("peso_tcm")
                                        ) + " Kg"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.domande_tcm.contraente_uguale_assicurato
                              .value == "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm(
                                                  "cognome"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue("cognome")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm("nome")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue("nome")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.domande_tcm.contraente_uguale_assicurato
                              .value == "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm(
                                                  "data_nascita"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue(
                                                "data_nascita"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm(
                                                  "codice_fiscale"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue(
                                                "codice_fiscale"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm("sesso")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue("sesso")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.domande_tcm.contraente_uguale_assicurato
                              .value == "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm(
                                                  "stato_nascita_tcm"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue(
                                                "stato_nascita_tcm"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm.domande_tcm.stato_nascita_tcm.value ==
                                    "ITALIA"
                                      ? _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaTcm(
                                                      "comune_nascita_tcm"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaTcmValue(
                                                    "comune_nascita_tcm"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.domande_tcm.stato_nascita_tcm.value ==
                                    "ITALIA"
                                      ? _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaTcm(
                                                      "cap_nascita_tcm"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaTcmValue(
                                                    "cap_nascita_tcm"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.domande_tcm.stato_nascita_tcm.value ==
                                    "ITALIA"
                                      ? _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelDomandaTcm(
                                                      "provincia_nascita_tcm"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDomandaTcmValue(
                                                    "provincia_nascita_tcm"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.domande_tcm.contraente_uguale_assicurato
                              .value == "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm(
                                                  "indirizzo_tcm"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue(
                                                "indirizzo_tcm"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm(
                                                  "comune_tcm"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue(
                                                "comune_tcm"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm(
                                                  "cap_tcm"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue("cap_tcm")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm(
                                                  "provincia_tcm"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue(
                                                "provincia_tcm"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "attivita_rischio_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "attivita_rischio_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "attivita_rischio_tcm"
                                ) == "NO"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "attivita_tcm"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "attivita_tcm"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("legend", [_vm._v("QUESTIONARIO ANAMNESTICO “A”")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "interrompe_lavoro_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "interrompe_lavoro_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "ricoveri_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "ricoveri_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "malattie_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "malattie_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "patologie_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "patologie_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "altro_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "altro_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm.isQuestionarioBTcmAbilitato
                          ? [
                              _c("legend", [
                                _vm._v("QUESTIONARIO ANAMNESTICO “B”"),
                              ]),
                              _c(
                                "q-list",
                                { attrs: { separator: "" } },
                                [
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaTcm(
                                                    "attesa_ricovero_tcm"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaTcmValue(
                                                  "attesa_ricovero_tcm"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaTcm(
                                                    "esami_diagnostici_tcm"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaTcmValue(
                                                  "esami_diagnostici_tcm"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaTcm(
                                                    "malattie_terapia_medica_tcm"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaTcmValue(
                                                  "malattie_terapia_medica_tcm"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaTcm(
                                                    "attivita_sportive_tcm"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaTcmValue(
                                                  "attivita_sportive_tcm"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaTcm(
                                                    "sostanze_stupefacenti_tcm"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaTcmValue(
                                                  "sostanze_stupefacenti_tcm"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabelDomandaTcm(
                                                    "inabile_tcm"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("q-item-label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDomandaTcmValue(
                                                  "inabile_tcm"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _c("legend", [_vm._v("DESIGNAZIONE DEI BENEFICIARI")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaTcm(
                                              "tipo_beneficiario_tcm"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaTcmValue(
                                            "tipo_beneficiario_tcm"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.domande_tcm.tipo_beneficiario_tcm.value ==
                                "elenco_beneficiari"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaTcm(
                                                  "totale_beneficiari_tcm"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaTcmValue(
                                                "totale_beneficiari_tcm"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.domande_tcm.tipo_beneficiario_tcm.value ==
                            "elenco_beneficiari"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-list",
                                          { attrs: { bordered: "" } },
                                          [
                                            _c(
                                              "q-item",
                                              [
                                                _c("q-item-section", [
                                                  _vm._v(
                                                    "Inserisci i dati dei Beneficiari"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item",
                                              [
                                                _vm.domande_tcm
                                                  .tipo_beneficiario_tcm
                                                  .value == "elenco_beneficiari"
                                                  ? _c(
                                                      "q-item-section",
                                                      [
                                                        _c(
                                                          "q-item-label",
                                                          {
                                                            attrs: {
                                                              caption: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getLabelDomandaTcm(
                                                                  "cognome_nome1_tcm"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("q-item-label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getDomandaTcmValue(
                                                                "cognome_nome1_tcm"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.domande_tcm
                                                  .tipo_beneficiario_tcm
                                                  .value == "elenco_beneficiari"
                                                  ? _c(
                                                      "q-item-section",
                                                      [
                                                        _c(
                                                          "q-item-label",
                                                          {
                                                            attrs: {
                                                              caption: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getLabelDomandaTcm(
                                                                  "data_nascita1_tcm"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("q-item-label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getDomandaTcmValue(
                                                                "data_nascita1_tcm"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.domande_tcm
                                                  .tipo_beneficiario_tcm
                                                  .value == "elenco_beneficiari"
                                                  ? _c(
                                                      "q-item-section",
                                                      [
                                                        _c(
                                                          "q-item-label",
                                                          {
                                                            attrs: {
                                                              caption: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getLabelDomandaTcm(
                                                                  "luogo_nascita1_tcm"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("q-item-label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getDomandaTcmValue(
                                                                "luogo_nascita1_tcm"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.domande_tcm
                                                  .tipo_beneficiario_tcm
                                                  .value == "elenco_beneficiari"
                                                  ? _c(
                                                      "q-item-section",
                                                      [
                                                        _c(
                                                          "q-item-label",
                                                          {
                                                            attrs: {
                                                              caption: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getLabelDomandaTcm(
                                                                  "residenza1_tcm"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("q-item-label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getDomandaTcmValue(
                                                                "residenza1_tcm"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.domande_tcm
                                                  .tipo_beneficiario_tcm
                                                  .value == "elenco_beneficiari"
                                                  ? _c(
                                                      "q-item-section",
                                                      [
                                                        _c(
                                                          "q-item-label",
                                                          {
                                                            attrs: {
                                                              caption: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getLabelDomandaTcm(
                                                                  "relazione1_tcm"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("q-item-label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getDomandaTcmValue(
                                                                "relazione1_tcm"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.domande_tcm
                                                  .tipo_beneficiario_tcm
                                                  .value == "elenco_beneficiari"
                                                  ? _c(
                                                      "q-item-section",
                                                      [
                                                        _c(
                                                          "q-item-label",
                                                          {
                                                            attrs: {
                                                              caption: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getLabelDomandaTcm(
                                                                  "percentuale_ripartizione1_tcm"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("q-item-label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getDomandaTcmValue(
                                                                "percentuale_ripartizione1_tcm"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            parseInt(
                                              _vm.domande_tcm
                                                .totale_beneficiari_tcm.value
                                            ) > 1
                                              ? _c(
                                                  "q-item",
                                                  [
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "cognome_nome2_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "cognome_nome2_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "data_nascita2_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "data_nascita2_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "luogo_nascita2_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "luogo_nascita2_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "residenza2_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "residenza2_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "relazione2_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "relazione2_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "percentuale_ripartizione2_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "percentuale_ripartizione2_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            parseInt(
                                              _vm.domande_tcm
                                                .totale_beneficiari_tcm.value
                                            ) > 2
                                              ? _c(
                                                  "q-item",
                                                  [
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "cognome_nome3_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "cognome_nome3_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "data_nascita3_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "data_nascita3_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "luogo_nascita3_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "luogo_nascita3_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "residenza3_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "residenza3_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "relazione3_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "relazione3_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "percentuale_ripartizione3_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "percentuale_ripartizione3_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            parseInt(
                                              _vm.domande_tcm
                                                .totale_beneficiari_tcm.value
                                            ) > 3
                                              ? _c(
                                                  "q-item",
                                                  [
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "cognome_nome4_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "cognome_nome4_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "data_nascita4_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "data_nascita4_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "luogo_nascita4_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "luogo_nascita4_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "residenza4_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "residenza4_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "relazione4_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "relazione4_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "percentuale_ripartizione4_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "percentuale_ripartizione4_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            parseInt(
                                              _vm.domande_tcm
                                                .totale_beneficiari_tcm.value
                                            ) > 4
                                              ? _c(
                                                  "q-item",
                                                  [
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "cognome_nome5_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "cognome_nome5_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "data_nascita5_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "data_nascita5_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "luogo_nascita5_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "luogo_nascita5_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "residenza5_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "residenza5_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "relazione5_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "relazione5_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.domande_tcm
                                                      .tipo_beneficiario_tcm
                                                      .value ==
                                                    "elenco_beneficiari"
                                                      ? _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              {
                                                                attrs: {
                                                                  caption: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getLabelDomandaTcm(
                                                                      "percentuale_ripartizione5_tcm"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("q-item-label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDomandaTcmValue(
                                                                    "percentuale_ripartizione5_tcm"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("br"),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm.isTutelaLegaleMedici
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Tutela legale settore Medico")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "specializzazione_tl_medici"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "specializzazione_tl_medici"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.analizzaRisposta(
                                          _vm.getDomandaAnalisiRischioFromName(
                                            "polizza_schifata"
                                          )
                                        ) === "Si"
                                          ? _c("Domanda", {
                                              ref: "domande_rc_edilizia.motivo_rifiuto_compagnia",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_rc_edilizia
                                                    .motivo_rifiuto_compagnia,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_tutela_medico"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_tutela_medico"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._m(19),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "tutela_legale_medici"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "tutela_legale_medici"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isTutelaLegaleTecnici
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Tutela legale settore Tecnico")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaAnalisiRischioFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.analizzaRisposta(
                                          _vm.getDomandaAnalisiRischioFromName(
                                            "polizza_schifata"
                                          )
                                        ) === "Si"
                                          ? _c("Domanda", {
                                              ref: "domande_rc_edilizia.motivo_rifiuto_compagnia",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_rc_edilizia
                                                    .motivo_rifiuto_compagnia,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_tutela_tecnico"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_tutela_tecnico"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._m(20),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "tutela_legale_professionisti"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "tutela_legale_professionisti"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isCauzioni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Cauzioni")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipo_rischio_cauzioni"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipo_rischio_cauzioni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isAsseverazioni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Asseverazioni")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipo_rischio_asseverazioni"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipo_rischio_asseverazioni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isFideiussioni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Fideiussioni")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipo_rischio_fidejussione"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipo_rischio_fidejussione"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isFotovoltaico
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("All Risk Fotovoltaico")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "costo_impianto_fotovoltaico"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "costo_impianto_fotovoltaico"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "estensioni_fotovoltaico"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _c(
                                        "ul",
                                        _vm._l(
                                          _vm.estensioni_fotovoltaico,
                                          function (estensione, index) {
                                            return _c("li", { key: index }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(estensione.label) +
                                                  " "
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaFotovoltaico(
                                                  "indirizzo_fotovoltaico"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaFotovoltaicoValue(
                                                "indirizzo_fotovoltaico"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFotovoltaico(
                                              "comune_fotovoltaico"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFotovoltaicoValue(
                                            "comune_fotovoltaico"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFotovoltaico(
                                              "cap_fotovoltaico"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFotovoltaicoValue(
                                            "cap_fotovoltaico"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFotovoltaico(
                                              "provincia_fotovoltaico"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFotovoltaicoValue(
                                            "provincia_fotovoltaico"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFotovoltaico(
                                              "tipologia_fotovoltaico"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFotovoltaicoLabel(
                                            "tipologia_fotovoltaico"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFotovoltaico(
                                              "tipo_installazione"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFotovoltaicoLabel(
                                            "tipo_installazione"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.domande_fotovoltaico.tipo_installazione
                                  .value === "tetto"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaFotovoltaico(
                                                  "altezza_impianto"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaFotovoltaicoLabel(
                                                "altezza_impianto"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _vm.domande_fotovoltaico.tipo_installazione
                                  .value === "tetto"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaFotovoltaico(
                                                  "numero_piani"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaFotovoltaicoLabel(
                                                "numero_piani"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFotovoltaico(
                                              "numero_inverter"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFotovoltaicoLabel(
                                            "numero_inverter"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaFotovoltaico(
                                              "numero_pannelli1"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaFotovoltaicoLabel(
                                            "numero_pannelli1"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.domande_fotovoltaico.numero_inverter
                                  .value >= 2
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaFotovoltaico(
                                                  "numero_pannelli2"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaFotovoltaicoLabel(
                                                "numero_pannelli2"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.domande_fotovoltaico.numero_inverter
                                  .value >= 3
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaFotovoltaico(
                                                  "numero_pannelli3"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaFotovoltaicoLabel(
                                                "numero_pannelli3"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaAnalisiRischioFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_fotovoltaico"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_fotovoltaico"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isDonazioni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Donazioni Immobili")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipologia_donazione"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipologia_donazione"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "valore_donazione"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "valore_donazione"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm.isDroni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("SARP Droni")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "totale_droni"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "totale_droni"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "costruttore_drone_1"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "costruttore_drone_1"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "modello_drone_1"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "modello_drone_1"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "anno_costruzione_drone_1"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "anno_costruzione_drone_1"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipologia_drone_1"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipologia_drone_1"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "peso_drone_1"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "peso_drone_1"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.totale_droni >= 2
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "costruttore_drone_2"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "costruttore_drone_2"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "modello_drone_2"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "modello_drone_2"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "anno_costruzione_drone_2"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "anno_costruzione_drone_2"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_drone_2"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_drone_2"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "peso_drone_2"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "peso_drone_2"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 3
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "costruttore_drone_3"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "costruttore_drone_3"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "modello_drone_3"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "modello_drone_3"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "anno_costruzione_drone_3"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "anno_costruzione_drone_3"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_drone_3"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_drone_3"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "peso_drone_3"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "peso_drone_3"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 4
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "costruttore_drone_4"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "costruttore_drone_4"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "modello_drone_4"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "modello_drone_4"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "anno_costruzione_drone_4"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "anno_costruzione_drone_4"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_drone_4"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_drone_4"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "peso_drone_4"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "peso_drone_4"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 5
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "costruttore_drone_5"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "costruttore_drone_5"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "modello_drone_5"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "modello_drone_5"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "anno_costruzione_drone_5"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "anno_costruzione_drone_5"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_drone_5"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_drone_5"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "peso_drone_5"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "peso_drone_5"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 6
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "costruttore_drone_6"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "costruttore_drone_6"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "modello_drone_6"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "modello_drone_6"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "anno_costruzione_drone_6"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "anno_costruzione_drone_6"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_drone_6"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_drone_6"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "peso_drone_6"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "peso_drone_6"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 7
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "costruttore_drone_7"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "costruttore_drone_7"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "modello_drone_7"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "modello_drone_7"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "anno_costruzione_drone_7"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "anno_costruzione_drone_7"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_drone_7"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_drone_7"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "peso_drone_7"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "peso_drone_7"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 8
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "costruttore_drone_8"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "costruttore_drone_8"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "modello_drone_8"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "modello_drone_8"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "anno_costruzione_drone_8"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "anno_costruzione_drone_8"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_drone_8"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_drone_8"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "peso_drone_8"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "peso_drone_8"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 9
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "costruttore_drone_9"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "costruttore_drone_9"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "modello_drone_9"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "modello_drone_9"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "anno_costruzione_drone_9"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "anno_costruzione_drone_9"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_drone_9"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_drone_9"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "peso_drone_9"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "peso_drone_9"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni === 10
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "costruttore_drone_10"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "costruttore_drone_10"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "modello_drone_10"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "modello_drone_10"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "anno_costruzione_drone_10"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "anno_costruzione_drone_10"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "tipologia_drone_10"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "tipologia_drone_10"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "peso_drone_10"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "peso_drone_10"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "sinistri_droni"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_droni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm.isCatastrofali
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Rischi Catastrofali")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipologia_fabbricato_catastrofali"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipologia_fabbricato_catastrofali"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipo_dimora_catastrofali"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipo_dimora_catastrofali"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipo_fabbricato_catastrofali"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipo_fabbricato_catastrofali"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipo_abitazione_catastrofali"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipo_abitazione_catastrofali"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "materiale_costruzione_catastrofali"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "materiale_costruzione_catastrofali"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "metri_quadri_catastrofale"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "metri_quadri_catastrofale"
                                            )
                                          )
                                        ) + " mq"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaCatastrofali(
                                              "piani_interrati"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaCatastrofaliValue(
                                            "piani_interrati"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaCatastrofali(
                                              "piani_fuori_terra"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaCatastrofaliValue(
                                            "piani_fuori_terra"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaCatastrofali(
                                              "indirizzo_catastrofali"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaCatastrofaliValue(
                                            "indirizzo_catastrofali"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaCatastrofali(
                                              "comune_catastrofali"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaCatastrofaliValue(
                                            "comune_catastrofali"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaCatastrofali(
                                              "cap_catastrofali"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaCatastrofaliValue(
                                            "cap_catastrofali"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "provincia_catastrofali"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "provincia_catastrofali"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c("q-item-label", { attrs: { caption: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLabelAnalisiRischioFromName(
                                        "sinistri_catastrofali"
                                      )
                                    ) + " "
                                  ),
                                ]),
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.analizzaRisposta(
                                        _vm.getDomandaAnalisiRischioFromName(
                                          "sinistri_catastrofali"
                                        )
                                      )
                                    ) + " mq"
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm.isStruttureSanitarie
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Strutture Sanitarie")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipologia_struttura_sanitaria"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipologia_struttura_sanitaria"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "struttura_sanitaria_convenzionata"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "struttura_sanitaria_convenzionata"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaStruttureSanitarie(
                                              "data_apertura_sanitarie"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaStruttureSanitarieValue(
                                            "data_apertura_sanitarie"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaStruttureSanitarie(
                                              "ubicazione_uguale_sede_sanitarie"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaStruttureSanitarieValue(
                                            "ubicazione_uguale_sede_sanitarie"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.domande_strutture_sanitarie
                                  .ubicazione_uguale_sede_sanitarie.value ===
                                "no"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaStruttureSanitarie(
                                                  "indirizzo_ubicazione_sanitarie"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaStruttureSanitarieValue(
                                                "indirizzo_ubicazione_sanitarie"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.domande_strutture_sanitarie
                              .ubicazione_uguale_sede_sanitarie.value === "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaStruttureSanitarie(
                                                  "comune_ubicazione_sanitarie"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaStruttureSanitarieValue(
                                                "comune_ubicazione_sanitarie"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaStruttureSanitarie(
                                                  "cap_ubicazione_sanitarie"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaStruttureSanitarieValue(
                                                "cap_ubicazione_sanitarie"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaStruttureSanitarie(
                                                  "provincia_ubicazione_sanitarie"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaStruttureSanitarieValue(
                                                "provincia_ubicazione_sanitarie"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(21),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "strutture_sanitarie"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "strutture_sanitarie"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          ) === "SI",
                                        expression:
                                          "getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Impresa di assicurazione"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "compagnia"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Data di scadenza della copertura"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "scadenza_copertura"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_struttura_sanitaria"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_struttura_sanitaria"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isShengen
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Cauzioni Shengen")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipologia_shengen"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipologia_shengen"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "paese_partenza_shengen"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "paese_partenza_shengen"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "data_partenza_shengen"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "data_partenza_shengen"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "paese_destinazione_shengen"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "paese_destinazione_shengen"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "data_rientro_shengen"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "data_rientro_shengen"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [
                          _vm._v("Informazioni sulla persona ospitata"),
                        ]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaShengen(
                                              "cognome_shengen"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaShengenValue(
                                            "cognome_shengen"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaShengen(
                                              "nome_shengen"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaShengenValue(
                                            "nome_shengen"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaShengen(
                                              "data_nascita_shengen"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaShengenValue(
                                            "data_nascita_shengen"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaShengen(
                                              "numero_passaporto_shengen"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaShengenValue(
                                            "numero_passaporto_shengen"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(22),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "shengen"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive("shengen"),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm.isAssociazioni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Associazioni")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "segmento_associazioni"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "segmento_associazioni"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "servizi_svolti_associazioni"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "servizi_svolti_associazioni"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipologia_ente_associazioni"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipologia_ente_associazioni"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaShengen(
                                              "numero_associati_associazioni"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaShengenValue(
                                            "numero_associati_associazioni"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaShengen(
                                              "numero_volontari_associazioni"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaShengenValue(
                                            "numero_volontari_associazioni"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaShengen(
                                              "numero_dipendenti_associazioni"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaShengenValue(
                                            "numero_dipendenti_associazioni"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._m(23),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "associazioni"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive("associazioni"),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          ) === "SI",
                                        expression:
                                          "getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Impresa di assicurazione"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "compagnia"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Data di scadenza della copertura"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "scadenza_copertura"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_struttura_associazioni"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_struttura_associazioni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isMultirischioAgricoltura
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio Agricoltura")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "tipo_fabbricato_agricoltura"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "tipo_fabbricato_agricoltura"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelAnalisiRischioFromName(
                                              "materiale_fabbricato_agricoltura"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "materiale_fabbricato_agricoltura"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "tipologia_contraente_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "tipologia_contraente_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "valutazione_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "valutazione_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "allarme_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "allarme_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "numero_post_letto_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "numero_post_letto_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "anno_costruzione_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "anno_costruzione_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "superficie_mq_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "superficie_mq_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "superficie_ettari_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "superficie_ettari_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "stabilmente_abitata_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "stabilmente_abitata_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "numero_soci_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "numero_soci_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "numero_dipendenti_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "numero_dipendenti_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelDomandaAgricoltura(
                                              "ubicazione_uguale_sede_agricoltura"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDomandaAgricolturaValue(
                                            "ubicazione_uguale_sede_agricoltura"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.domande_multirischio_agricoltura
                                  .ubicazione_uguale_sede_agricoltura.value ===
                                "no"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaAgricoltura(
                                                  "indirizzo_ubicazione_agricoltura"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaAgricolturaValue(
                                                "indirizzo_ubicazione_agricoltura"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.domande_multirischio_agricoltura
                              .ubicazione_uguale_sede_agricoltura.value === "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaAgricoltura(
                                                  "comune_ubicazione_agricoltura"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaAgricolturaValue(
                                                "comune_ubicazione_agricoltura"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelDomandaAgricoltura(
                                                  "cap_ubicazione_agricoltura"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDomandaAgricolturaValue(
                                                "cap_ubicazione_agricoltura"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelAnalisiRischioFromName(
                                                  "provincia_agricoltura"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaAnalisiRischioFromName(
                                                  "provincia_agricoltura"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(24),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCompagniaSelezionata(
                                              "multirischio_agricoltura"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive(
                                "multirischio_agricoltura"
                              ),
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.analizzaRisposta(garanzia)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          ) === "SI",
                                        expression:
                                          "getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Impresa di assicurazione"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "compagnia"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Data di scadenza della copertura"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "scadenza_copertura"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelAnalisiRischioFromName(
                                            "sinistri_struttura_agricoltura"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaAnalisiRischioFromName(
                                              "sinistri_struttura_agricoltura"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Proponente - Informazioni generali")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Informazioni assicurative")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Informazioni assicurative")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Informazioni assicurative")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche proposta")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }