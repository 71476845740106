var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.questionario_caricato
    ? _c("div", { staticClass: "row justify-center" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-10", attrs: { align: "left" } },
          [
            _vm._m(0),
            _c(
              "q-list",
              { attrs: { separator: "" } },
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Cognome, Nome / Ragione Sociale"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(_vm._s(_vm.getDatiCliente("nominativo"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Indirizzo"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(_vm.getDatiResidenzaCliente("indirizzo"))
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Civico"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(_vm._s(_vm.getDatiResidenzaCliente("civico"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Comune"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(_vm._s(_vm.getDatiResidenzaCliente("comune"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Provincia"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(_vm.getDatiResidenzaCliente("provincia"))
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("CAP"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(_vm._s(_vm.getDatiResidenzaCliente("cap"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Codice Fiscale"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(_vm._s(_vm.getDatiCliente("codice_fiscale"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Partita IVA"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(_vm._s(_vm.getDatiCliente("partita_iva"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Comune di nascita"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(_vm._s(_vm.getDatiCliente("comune_nascita"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Provincia di nascita"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(_vm.getDatiCliente("provincia_nascita"))
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Attività svolta"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(_vm.getRispostaQuestionariFromName("lavoro"))
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Tipo attività"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getRispostaQuestionariFromName("tipo_lavoro")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm.isSindacoRevisore
                      ? _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v("Svolgi attività di Sindaco Revisore?"),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getRispostaQuestionariFromName(
                                    "sindaco_revisore"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isSindacoRevisore
                      ? _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                "A quale percentuale corrisponde il fatturato di Sindaco/Revisore?"
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(_vm._s(_vm.getPercentualeSindacoRevisore)),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Sei un professionista iscritto ad un albo e/o abilitato all'esercizio dell'attivita` professionale?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getRispostaQuestionariFromName(
                                "iscrizione_albo"
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _vm._m(1),
            _c(
              "q-list",
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomandaLabel("data_inizio_attivita")
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomandaValue("data_inizio_attivita")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomandaLabel("data_iscrizione_albo")
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomandaValue("data_iscrizione_albo")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomandaLabel(
                                "numero_iscrizione_albo"
                              )
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomandaValue(
                                "numero_iscrizione_albo"
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(_vm.getObjectDomandaLabel("sezione_albo"))
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(_vm.getObjectDomandaValue("sezione_albo"))
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomandaLabel(
                                "data_ultimo_aggiornamento_prof"
                              )
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomandaValue(
                                "data_ultimo_aggiornamento_prof"
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda("numero_crediti_conseguiti")
                                .label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda("numero_crediti_conseguiti")
                                .value
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _vm._m(2),
            _c(
              "q-list",
              { attrs: { separator: "" } },
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c(
                          "q-item-label",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isTaylorMade,
                                expression: "!isTaylorMade",
                              },
                            ],
                            attrs: { caption: "" },
                          },
                          [_vm._v("Compagnia")]
                        ),
                        _c(
                          "q-item-label",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isTaylorMade,
                                expression: "!isTaylorMade",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.getCompagniaSelezionata) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(
                  _vm.getElencoGaranzieAggiuntive,
                  function (garanzia, index) {
                    return _c(
                      "q-item",
                      { key: index },
                      [
                        _c(
                          "q-item-section",
                          [
                            _c(
                              "q-item-label",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isTaylorMade,
                                    expression: "!isTaylorMade",
                                  },
                                ],
                                attrs: { caption: "" },
                              },
                              [_vm._v(_vm._s(garanzia.label))]
                            ),
                            _c(
                              "q-item-label",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isTaylorMade,
                                    expression: "!isTaylorMade",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.analizzaRisposta(garanzia)) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda("chiude_attivita").label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda("chiude_attivita").value
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm.domandaIsVisible(_vm.getObjectDomanda("vuole_postuma"))
                      ? _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda("vuole_postuma").label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda("vuole_postuma").value
                                )
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              2
            ),
            _c("br"),
            _vm._m(3),
            _c(
              "q-list",
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato.fatturato_corrente"
                              ).label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato.fatturato_corrente"
                              ).value
                            ) + " Euro"
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Fatturato consuntivo " +
                              _vm._s(new Date().getFullYear() - 1)
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getRispostaQuestionariFromName("fatturato")
                            ) + " Euro"
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato.fatturato_due_anni_fa"
                              ).label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato.fatturato_due_anni_fa"
                              ).value
                            ) + " Euro"
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato.fatturato_tre_anni_fa"
                              ).label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato.fatturato_tre_anni_fa"
                              ).value
                            ) + " Euro"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Hai un Cliente su cui fatturi piu` del 50% del fatturato?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.rispostaClientePiu50Fatturato) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.haveClientePiu50Fatturato,
                        expression: "haveClientePiu50Fatturato",
                      },
                    ],
                  },
                  [
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "cliente_50_percento.nominativo"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "cliente_50_percento.nominativo"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "cliente_50_percento.settore_attivita"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "cliente_50_percento.settore_attivita"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "cliente_50_percento.altro"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "cliente_50_percento.altro"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "cliente_50_percento.perc_fatturato"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "cliente_50_percento.perc_fatturato"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _vm._m(4),
            _c("br"),
            _c(
              "q-list",
              { attrs: { bordered: "", separator: "" } },
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [
                          _c("strong", [_vm._v("ATTIVITÀ")]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [
                          _c("strong", [
                            _vm._v(
                              "Suddivisione Percentuale Fatturato Anno precedente"
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [
                          _c("strong", [
                            _vm._v(
                              "Suddivisione Percentuale Fatturato Anno corrente"
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [
                          _c("strong", [_vm._v("Attivita ordinaria")]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.percentuale_fatturato_attivita_ordinaria_anno_precedente()
                            ) + " %"
                          ),
                        ]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getImportoPrecedente(
                                _vm.percentuale_fatturato_attivita_ordinaria_anno_precedente()
                              )
                            ) + " Euro"
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.percentuale_fatturato_attivita_ordinaria_anno_corrente()
                            ) + " %"
                          ),
                        ]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getImportoCorrente(
                                _vm.percentuale_fatturato_attivita_ordinaria_anno_corrente()
                              )
                            ) + " Euro"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(
                  _vm.getObjectDomanda("distribuzione_fatturato"),
                  function (attivita, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _vm.domandaIsVisible(attivita.perc_anno_corrente)
                          ? _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", [
                                      _vm._v(_vm._s(attivita.label.label)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          attivita.perc_anno_precedente.value
                                        ) + " %"
                                      ),
                                    ]),
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getImportoPrecedente(
                                              attivita.perc_anno_precedente
                                                .value
                                            )
                                          ) + " Euro"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          attivita.perc_anno_corrente.value
                                        ) + " %"
                                      ),
                                    ]),
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getImportoPrecedente(
                                              attivita.perc_anno_corrente.value
                                            )
                                          ) + " Euro"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }
                ),
              ],
              2
            ),
            _c("br"),
            _vm._m(5),
            _c(
              "q-list",
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Percentuale fatturato Italia"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(_vm._s(_vm.percentualeFatturatoItalia) + " %"),
                        ]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getImportoFatturatoEstero(
                                _vm.percentualeFatturatoItalia
                              )
                            ) + " Euro"
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato_estero.perc_fatturato_ue"
                              ).label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato_estero.perc_fatturato_ue"
                              ).value
                            ) + " %"
                          ),
                        ]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getImportoFatturatoEstero(
                                _vm.getObjectDomanda(
                                  "fatturato_estero.perc_fatturato_ue"
                                ).value
                              )
                            ) + " Euro"
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato_estero.perc_fatturato_non_ue"
                              ).label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato_estero.perc_fatturato_non_ue"
                              ).value
                            ) + " %"
                          ),
                        ]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getImportoFatturatoEstero(
                                _vm.getObjectDomanda(
                                  "fatturato_estero.perc_fatturato_non_ue"
                                ).value
                              )
                            ) + " Euro"
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.lavoraUsaCanada,
                            expression: "lavoraUsaCanada",
                          },
                        ],
                      },
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato_estero.perc_fatturato_usa_canada"
                              ).label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "fatturato_estero.perc_fatturato_usa_canada"
                              ).value
                            ) + " %"
                          ),
                        ]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getImportoFatturatoEstero(
                                _vm.getObjectDomanda(
                                  "fatturato_estero.perc_fatturato_usa_canada"
                                ).value
                              )
                            ) + " Euro"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.ha_funzioni_pubbliche,
                    expression: "ha_funzioni_pubbliche",
                  },
                ],
              },
              [
                _vm._m(6),
                _c("br"),
                _c(
                  "q-list",
                  { attrs: { bordered: "", separator: "" } },
                  [
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _vm._v(
                                "Si prega di specificare le funzioni ricoperte dall'Assicurato e le relative anzianità"
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _c("strong", [_vm._v("DESCRIZIONE")]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _c("strong", [_vm._v("DATA INIZIO ATTIVITÀ")]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      Object.values(_vm.getObjectDomanda("funzioni_pubbliche")),
                      function (dato, index) {
                        return _c(
                          "q-item",
                          { key: index, staticClass: "q-mx-sm q-px-sm" },
                          [
                            _c("q-item-section", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    dato[
                                      "funz_pubbliche_descrizione" + (index + 1)
                                    ].value
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("q-item-section", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    dato["funz_pubbliche_datadal" + (index + 1)]
                                      .value
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("Domanda", {
              ref: "domande.dati_consigliere_amministrazione",
              attrs: {
                domanda: _vm.getObjectDomanda(
                  "dati_consigliere_amministrazione"
                ),
                disabled: "",
              },
            }),
            _c("br"),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSindacoRevisore,
                    expression: "isSindacoRevisore",
                  },
                ],
              },
              [
                _vm._m(7),
                _c("br"),
                _c(
                  "q-list",
                  { attrs: { bordered: "", separator: "" } },
                  [
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _c("strong", [_vm._v("SOCIETÀ")]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _c("strong", [_vm._v("Capitale sociale")]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _c("strong", [_vm._v("Fatturato")]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _c("strong", [_vm._v("Settore attività")]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _c("strong", [_vm._v("Ruolo")]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _c("strong", [
                                _vm._v(
                                  "Società o controllante quotate in borsa"
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _c("strong", [_vm._v("Data nomina")]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      Object.values(_vm.getObjectDomanda("dati_sindaco")),
                      function (dato, index) {
                        return _c(
                          "q-item",
                          { key: index, staticClass: "q-mx-sm q-px-sm" },
                          [
                            _c(
                              "q-item-section",
                              [
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      dato["dati_sindaco_societa" + (index + 1)]
                                        .value
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "q-item-section",
                              [
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      dato[
                                        "dati_sindaco_capitale_sociale" +
                                          (index + 1)
                                      ].value
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "q-item-section",
                              [
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      dato[
                                        "dati_sindaco_fatturato" + (index + 1)
                                      ].value
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "q-item-section",
                              [
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      dato["dati_sindaco_settore" + (index + 1)]
                                        .value
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "q-item-section",
                              [
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      dato["dati_sindaco_ruolo" + (index + 1)]
                                        .value
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "q-item-section",
                              [
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      dato[
                                        "dati_sindaco_quotate_borsa" +
                                          (index + 1)
                                      ].value
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "q-item-section",
                              [
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      dato[
                                        "dati_sindaco_data_nomina" + (index + 1)
                                      ].value
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("br"),
            _vm._m(8),
            _c(
              "q-list",
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Hai mai stipulato polizze per questo rischio?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            " " + _vm._s(_vm.havePolizzaStessoRischio) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.havePolizzaStessoRischio === "SI",
                        expression: "havePolizzaStessoRischio === 'SI' ",
                      },
                    ],
                  },
                  [
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v("Impresa di assicurazione"),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getRispostaQuestionariFromName(
                                    "compagnia"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v("Data di scadenza della copertura"),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getRispostaQuestionariFromName(
                                    "scadenza_copertura"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c(
                              "q-item-label",
                              [
                                _c("q-item-label", { attrs: { caption: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getObjectDomanda(
                                        "copertura_assicurativa_precedente.nome_assicuratore"
                                      ).label
                                    )
                                  ),
                                ]),
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getObjectDomanda(
                                        "copertura_assicurativa_precedente.nome_assicuratore"
                                      ).value
                                    ) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.massimale"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.massimale"
                                  ).value
                                ) + " Euro"
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.franchigie_scoperti"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.franchigie_scoperti"
                                  ).value
                                ) + " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.premio_lordo"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.premio_lordo"
                                  ).value
                                ) + " Euro"
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.retroattivita"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.retroattivita"
                                  ).value
                                ) + " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(_vm._s(_vm.PolizzaSchifataDaCompagnia)),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _vm.PolizzaSchifataDaCompagnia === "SI"
                      ? _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "motivo_rifiuto_compagnia"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "motivo_rifiuto_compagnia"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _vm._m(9),
            _c(
              "q-list",
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Hai mai ricevuto richieste di risarcimento inerenti l`attività professionale?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            " " + _vm._s(_vm.haveRichiesteRisarcimento) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.haveRichiesteRisarcimento === "SI",
                        expression: "haveRichiesteRisarcimento === 'SI'",
                      },
                    ],
                  },
                  [
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.data_richiesta_risarcimento"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.data_richiesta_risarcimento"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.periodo_contestazione"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.periodo_contestazione"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.valore_richiesta_danni"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.valore_richiesta_danni"
                                  ).value
                                ) + " Euro"
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.valore_sinistro_pagato"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.valore_sinistro_pagato"
                                  ).value
                                ) + " Euro"
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.pagamento_con_transazione"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.pagamento_con_transazione"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.pagamento_con_sentenza_civile"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.pagamento_con_sentenza_civile"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.descrizione_fatti"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.descrizione_fatti"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Sei a conoscenza di circostanze che possono portare ad una richiesta di risarcimento da parte di clienti?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.haveConoscenzaPossibiliRichiesteRisarcimento
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.haveConoscenzaPossibiliRichiesteRisarcimento ===
                          "SI",
                        expression:
                          "haveConoscenzaPossibiliRichiesteRisarcimento === 'SI'",
                      },
                    ],
                  },
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "sinistri.descrizione_possibili_richieste_danni"
                              ).label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "sinistri.descrizione_possibili_richieste_danni"
                              ).value
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Proponente - Informazioni generali")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Proponente - Dati professionali")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Informazioni sul fatturato")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Distribuzione del fatturato per Attività")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Fatturato internazionale")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Estensione funzioni pubbliche")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [
        _vm._v(
          "Società o Enti nelle quali il proponente è attualmente Sindaco revisore dei conti, Amministratore di società , Attività ODV"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Precedenti assicurazioni per questo rischio")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Informazioni sui sinistri")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }